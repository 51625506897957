import { SEO } from "../core-nextv3/util/seo";
import { calls } from "../core-nextv3/util/call.api";
import { getAccount } from "../core-nextv3/account/account.api";
import { collectionDocument, getDocument } from "../core-nextv3/document/document.api";
import { ACCOUNT_SETTING, CATEGORY_SETTING, DICOUNT_PRODUCT_PAGE_SETTING, GATEWAY_SETTING, MENU_SETTING, TABLE_PERSON_SETTING, TOP_HEADER_PAGE_SETTING } from "../setting/setting";

export default async function withHeader(propsFunc: any) 
{
	const [account, categories, menu, topHeader, popupPageDiscount, gateway, tablePerson] = await calls(
		getAccount(ACCOUNT_SETTING),
		collectionDocument(CATEGORY_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		}
		)),
		collectionDocument(MENU_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		}
		)),
		getDocument(
			TOP_HEADER_PAGE_SETTING.merge({
			document: {
				referencePath: "default/page/documents/Mws3J3wXIBscbZLnkVuy",
			},
		})),
		getDocument(DICOUNT_PRODUCT_PAGE_SETTING),
		getDocument(GATEWAY_SETTING),
		getDocument(TABLE_PERSON_SETTING),
	);

	// console.log('aqui', menu)
	const props = {
		account	  		  : account?.data || {},
		seo 	  		  : SEO().mergeAccount(account?.data),
		categories 		  : categories?.collection || [],
		menu	   		  : menu?.collection || [],
		topHeader  		  : topHeader.data || {},
		popupPageDiscount : popupPageDiscount.data || {},
		gateway 		  : gateway?.data || {},
		tablePerson       : tablePerson?.data || {},
	};

	const pageStaticProps = await propsFunc(props);
	pageStaticProps.props = { ...props, ...pageStaticProps.props };

	return pageStaticProps;
}
