import { setting } from "../core-nextv3/util/setting";

const platform = {
	id    : "Z0ZedpH3",
	label : "E-commerce Muna",
	value : "ecommerce"
}

const THEME_SETTING : any = {
	perPage															 : 40,
	minValue                             : 50,
	maxParcel                            : 6,
	revalidate                           : 60 * 30,
	pixDiscount                          : 7,
	//aspectRatio                          : 683 / 1024,
	widthBannerMain : 1920,
	heightBannerMain : 813,
	widthProductThumb : 683,
	heightProductThumb : 1024,
	imageCDN 				 : true,
	productPath                          : '/produto/',
	noteShipping                         : '',
	disabledFeatures                     : true,
	disabledHeaderInline                 : true,
	disabledHeaderSticke                 : true,
	// typeColorVariantImage           : 'square',
	disabledImageAndDescriptionModalSize : true,
	disabledOptionModaSize               : true,
	disableOptionModalSize               : true,
	carouselImagesSticky                 : false,
	disabledFotoCategoryMobile           : false,
	disabledCarouselPhotosSmall          : true,
	disabledDisplayColorVatiant          : true,
	disabledPromotionalPercentage        : false,
	disabledIconWhatsAppPageProduct      : false,
	disabledInfiniteScroll							 : true,
	footerInfo                           : '<p>© 2024 Todos os direitos reservados à SEULBITTI E-COMMERCE MODAS LTDA</p><p>CNPJ: 34.249.900/0001-99</p><p>Rua Professor Cesare Lombroso, 154 - Bom Retiro, São Paulo - SP, 01122-020</p>',
	sacInfo1                             : '<p>Atendimento sobre ao seu pedido ou devolução.</p><p>Email: dizai@muna.com.br<br/>Whatsapp: (11) 94724-5795</p><p>Segunda à Sexta das 09h00 às 16h00</p>',
	cookieConsent                        : `Olá cliente Muna, utilizamos cookies com fins analíticos e para mostrar anúncios relacionados a suas preferências. Para mais informações visite a nossa <a href="/loja/politica-de-privacidade" style={{ fontSize: "14px" }}>Política de Privacidade</a>`,
};

const parseSizeVariant = (label:string) =>
{
	return label.split(' / ')[0];
}

// MENU HEADER
const MENU_HEADER_STATIC = [{
	label : 'Como Comprar',
	url   : '/loja/como-comprar',
},
{
	label : 'Dúvidas ?',
	url   : '/loja/duvidas',
}]

// MENU FOOTER
const MENU_FOOTER_STATIC = [{
	label : 'Sobre',
	url   : '/loja/sobre-nos',
	children : [{
		label : 'Como Comprar',
		url   : '/loja/como-comprar',
	},
	{
		label : 'Privacidade e Segurança',
		url   : '/loja/politica-de-privacidade',
	},
	{
		label : 'Seja um Revendedor',
		url   : '/seja-um-revendedor',
	},
	{
		label : 'Nossas Avaliações',
		url   : '/avaliacoes',
	}]
},
{
	label : 'Ajuda',
	url   : '/loja/duvidas',
	children : [{
		label : 'Dúvidas',
		url   : '/loja/duvidas',
	},
	{
		label : 'Prazo e Entrega',
		url   : '/loja/prazo-e-entrega',
	}]
}];

// ACCOUNT
const ACCOUNT_SETTING = setting({
	accid : "default",
	appid: "accounts",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/accounts/grids/BkjMWCLOWO35yX0mOGq0",
	},
});

// CATEGORY
const CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/6diDjwiaSnSRWAsxwiL8",
	},
});

// MENU
const MENU_SETTING = setting({
	accid: "default",
	appid: "menuEcom",
	colid: "documentsMenu",
	platform: platform,
	orderBy: "order",
	perPage : 80,
	map: true,
	mapItems: {
	  	referencePath: "default/menuEcom/grids/kt0hwPfd3SNVeQvQrCX4",
	},
});

const SLUG_CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/kKKudYPqflEMwv47sFdg",
	},
});

// COLLECTION
const COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/zybCVEmnSyXY2nGr25dZ",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

const SLUG_COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/kw3yIEJmry2wcO04xkPu",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

//PAGE
const PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/iBqwfjj1mYhVKHRnUuTn",
	},
});

//COLLECTION PAGE
const COLLECTION_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/3nPSpGQi6NnHCr8irdE3",
	},
	document: {
		referencePath: "default/page/documents/IuzEywvzHErhbvGYoIgQ",
	},
});

// TROCA PAGE
const TROCA_PAGE_SETTING = PAGE_SETTING.merge(
{
	document: {
		referencePath: "default/page/documents/GpDJGWHNsmtjiPjJtVt2",
	},
})

// SALE PAGE
const SALE_PAGE_SETTING = PAGE_SETTING.merge(
{
	document: {
		referencePath: "default/page/documents/vAhuufrUifjQNPXycCTt",
	},
})

//HOME PAGE
const HOME_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/TC6n5YDMziuTEwYMheqt",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	id : "yUD8uh6SE4AnuPAe7gRX",
});

// PAGE PIX
const PAGE_PIX_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/K01PxzDM1ZV4Jji9yzg4",
	},
	document: {
		referencePath: "default/page/documents/ZWwyDIAJAYiEPGDCCADA",
	},
});

//HOME TWO PAGE
const HOME_TWO_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/grNuD3ChtAhtYv6VkDRb",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	id : "gO0aKmCBvLTitYcxQfhD",
});

//PAGE DEALER
const PAGE_SELLER_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/PuoAgBgBkptpElNWKYl5",
	},
	document: {
		referencePath: "default/page/documents/4OpK3veO70oZCl48l6Dv"
	}
});

//ORDER
const ORDER_SETTING = setting({
	accid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

//ORDER EXPRESS
const ORDER_EXPRESS_SETTING = setting({
	accid: "default",
	appid: "orderExpress",
	colid: "documentsOrderExpress",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/orderExpress/forms/OlXAFUDY2F91cpijO8nx",
	},
	mapItems: {
		referencePath: "default/orderExpress/grids/ZUGg0KKetwIWaAbTevL6",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

//REORDER
const REORDER_SETTING = setting({
	accid: "default",
	appid: "reorder",
	colid: "documentsReorder",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	reorderAmount : 1,
	ownerField : 'client',
	form: {
        referencePath: "default/reorder/forms/sxeWpMAjEZAJRSWkmq4r",
	},
	mapItems: {
		referencePath: "default/reorder/grids/PxB0gJo1P46N9qfC4ufN",
	}
});

//COLOR VARIANT
const COLOR_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/XRN1sB9yiQe3VMQVcwgA",
	},
	document : {
		referencePath: "default/variant/documents/Z7k8hiv6kmXnljSHNQZ8",		
	}
});

//SIZE VARIANT
const SIZE_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/XRN1sB9yiQe3VMQVcwgA",
	},
	document : {
		referencePath: "default/variant/documents/P2N4ByyklQ7kYUR2uCJn",		
	}
});

//FULL PRODUCT
const FULL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/product/grids/ZUnonLswnK67KE4lnDmg",
	},
});

//PARTIAL PRODUCT
const PARTIAL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/product/grids/M3iq5lXMxV9zZTJcYIIm",
	},
});

const PARTIAL_PRODUCT_VARIANT_SETTING = setting({
	accid: "default",
	appid: "productsVariant",
	colid: "documentsProductsVariant",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
	mapItems: {
		referencePath: "default/productsVariant/grids/OTWVdzFANifBvnjsKw6C",
	},
});

//SLUG PRODUCT
const SLUG_PRODUCT_SETTING = setting({
	accid : "default",
	appid : "product",
	colid : "documents",
	level : 1,
	map : true,
	asc : false,
	orderBy : "postdate",
	mapItems : {
		referencePath: "default/product/grids/AZr54wxR0nI1TNOD2J0s",
	},
});

//FEATURES PRODUCTS
const FEATRES_PRODUCTS_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/nB8xH08lIcyUfqY0TsgG",
	},
	document: {
		referencePath: "default/page/documents/IoADhxzou8tL4S3U1bSM",
	}
});

//CART
const CART_SETTING = setting({
	accid: "default",
	appid: "cart",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/cart/forms/yi8sxDEbExlshNIXREV3",
	},
	mapItems: {
		// referencePath: "default/cart/grids/E2VYLKMKhIa3DkPfZsHn",
		referencePath: "default/cart/grids/Urv2gFGWg2c9I83jjLOm",
	},
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

// FAVORITE
const FAVORITE_SETTING = setting({
	accid: "default",
	appid: "favorite",
	colid: "documents",
	skuImage:true,
	platform: platform,
	orderBy : 'postdate',
	asc : false,
	form: {
	  	referencePath: "default/favorite/forms/rN4ZbG32CwW11BH9ztlW",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/favorite/grids/6VvZ75JUG09usg2okeGm",
	},		
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

// RESALES
const RESALES_SETTING = setting({
	accid: "default",
	appid: "resales",
	colid: "documents",
	level:1,
	platform: platform,
	form: {
	  	referencePath: "default/favorite/forms/rN4ZbG32CwW11BH9ztlW",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/resales/grids/6d0FDu7ah6EKq6Xz5KzZ",
	},		
	stockName: {
		referencePath: "default/stockName/documents/sUA7GHZYfaMngc9XX4FJ",
	},
	priceName: {
		referencePath: "default/priceName/documents/6J20LmoEcmW5mU9DPlS1",
	},
});

// AUTH
const AUTH_SETTING = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/users/forms/5BMnB6DwQDHZ0ir8zatM",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/users/grids/TbNxyDAbzcEjPNHn2X5c",
	},	
});

// SELLER CONTACT
const SELLER_CONTACT_SETTING = setting({
	accid: "default",
	appid: "contactSeller",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/contactSeller/forms/45YQdGSiTZPB079TBxB8",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/contactSeller/grids/1trbpk9meKEkhZT1Uqmr",
	},	
});

// OPTION
const OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/ilEcDBizap1j0WVZJwES",
	},	
});

// PAYMENT METHODS OPTION
const PAYMENT_METHODS_OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	document: {
		referencePath: "default/option/documents/kdEtgv8BEwVRju3W5gmD",
  	},
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/ilEcDBizap1j0WVZJwES",
	},	
});

//GATEWAY
const GATEWAY_SETTING = setting({
	accid: "default",
	appid: "gateway",
	colid: "documents",
	platform: platform,
	document: {
	  referencePath: "default/gateway/documents/F2JEy0TFZjhd63XOCosX",
	},
	mapItems: {
		referencePath: "default/gateway/grids/S8htV2gbxahJ5VuoCyBC",
  },
});

// BANNER
const BANNER_SETTING = setting({
	accid: "default",
	appid: "banner",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "order",
	mapItems: {
	  	referencePath: "default/banner/grids/tbdlFvLpACmtWj9u1dNh",
	},
}); 

// TRACK
const TRACK_SETTING = setting({
	accid: "default",
	appid: "shippings",
	colid: "documentsShippings",
	platform: platform,
	shipping : {
		referencePath : 'default/shippings/documentsShippings/fRZkNznJyKkcVqlf3CNO'
	},
	mapItems: {
		referencePath: "default/accounts/grids/BkjMWCLOWO35yX0mOGq0",
	}
});

// ANALYTICS
const ANALYTICS_SETTING = setting({
	accid: "default",
	appid: "analytics",
	colid: "documents",
	platform: platform,
	form: {
	  	referencePath: "default/analytics/forms/Ri9eeK5NsSlGCEsq7eeJ",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/analytics/grids/RlHy1iwUHPjpmlGZ9Bh7",
	},		
});

//TOPHEADER
const TOP_HEADER_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/NG9iAgQXzrumGxe7XHFa",
	},
});

//DISCOUNT PRODUCT PAGE
const DICOUNT_PRODUCT_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/S9uuSQSy54jpLIJdA9Qm",
	},
	document: {
		referencePath: "default/page/documents/ogAiTS9Z6kRsu1yMcaSt"
	}
});

//TRACK BACK
const TRACK_BACK_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/OwFSt7QmIGJvuzGdfPYK",
	},
	document: {
		referencePath: "default/page/documents/8Ri9sSbPlyj6HnvfkQga"
	}
});

// ERRORS
const ERROS_SETTING = setting({
	accid: "default",
	appid: "erros",
	colid: "documentsErros",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/erros/forms/sSv7Lj2MLdLLHA4Fzk2s",
	},
	mapItems: {
		referencePath: "default/erros/grids/JfyS5xC2lX6URK7MYrAb",
	},
});

const REORDER_TYPE_OPTION = OPTION_SETTING.merge({
	id : 'idPG6M3pVTTn3LFgPope'
});

const REASONS_REORDER_OPTION = OPTION_SETTING.merge({
	id : 'uwrAdLdF3EUyVGoImFZA'
});

const REORDER_INFO : any = {
	name 			: 'Solicitar Troca/Crédito',
	labelNoteClient : 'Motivo da troca',
};

//COMMET
const COMMET_SETTING = setting({
	accid: "default",
	appid: "comment",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	asc:false,
	map: true,
	mapItems: {
		referencePath: "default/comment/grids/0SAZHs7a6tYEbJf2c1U3",
	},
	form: {
		referencePath: "default/comment/forms/RUHBFkTuTsKGOiVBbbVr",
	},
});

//COLOR_TABLE
const COLOR_TABLE_SETTING = setting ({
	accid: "default",
	appid: "colorTable",
	colid: "documents",
	map: true,
	asc: false,
	orderBy: "postdate",
	document: {
		referencePath: "default/colorTable/documents/Knhn49My8XPjMEXtp8Tf",
	},
	mapItems: {
		referencePath: "default/colorTable/grids/AWvxuBz3JlR6IMqA03sU",
	},
});

//COLOR_TABLE
const INSTAGRAM_FEEDS_SETTING = setting ({
	accid: "default",
	appid: "instagramFeeds",
	colid: "documentsInstagramFeeds",
	map: true,
	asc: false,
	orderBy: "date",
	perPage : 24,
	mapItems: {
		referencePath: "default/instagramFeeds/grids/FBUXoEYZdKI5ss3tzgKN",
	},
});

// PAGE REPLACEMENT => Mensagem de final de ano na pagina de troca
const PAGE_REPLACEMENT_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/FyBnNgmZy4G2tc9dDqoA",
	},
	document: {
		referencePath: "default/page/documents/lTkOWQjjM0Cc5Oeb2xZj",
	},
});

//TABLE_PERSON
const TABLE_PERSON_SETTING = setting ({
	accid: "default",
	appid: "measuresTablePerson",
	colid: "documents",
	map: true,
	asc: false,
	orderBy: "postdate",
	document: {
		referencePath: "default/measuresTablePerson/documents/uquwtwOpJiGzfJNfNtnF",
	},
	mapItems: {
		referencePath: "default/measuresTablePerson/grids/e9G8al1ZeeYf9BMo7rl4",
	},
});

export { 
	THEME_SETTING,
	TROCA_PAGE_SETTING,
	MENU_FOOTER_STATIC, 
	BANNER_SETTING, 
	ACCOUNT_SETTING, 
	PAGE_SETTING,
	COLLECTION_PAGE_SETTING,
	TRACK_SETTING, 
	MENU_HEADER_STATIC,
	ANALYTICS_SETTING,
	CATEGORY_SETTING, 
	COLOR_VARIANT_SETTING, 
	FAVORITE_SETTING,
	RESALES_SETTING,
	AUTH_SETTING,
	SELLER_CONTACT_SETTING,
	SIZE_VARIANT_SETTING, 
	PAYMENT_METHODS_OPTION_SETTING,
	FEATRES_PRODUCTS_SETTING,
	CART_SETTING, 
	COLLECTION_SETTING,
	SLUG_COLLECTION_SETTING,
	FULL_PRODUCT_SETTING, 
	ORDER_SETTING, 
	OPTION_SETTING, 
	HOME_PAGE_SETTING,
	HOME_TWO_PAGE_SETTING,
	PARTIAL_PRODUCT_SETTING, 
	SLUG_PRODUCT_SETTING,
	SLUG_CATEGORY_SETTING,
	MENU_SETTING,
	PARTIAL_PRODUCT_VARIANT_SETTING,
	TOP_HEADER_PAGE_SETTING,
	DICOUNT_PRODUCT_PAGE_SETTING,
	REORDER_SETTING,
	REORDER_TYPE_OPTION,
	ORDER_EXPRESS_SETTING,
	ERROS_SETTING,
	REORDER_INFO,
	COMMET_SETTING,
	REASONS_REORDER_OPTION,
	COLOR_TABLE_SETTING,
	GATEWAY_SETTING,
	TRACK_BACK_PAGE_SETTING,
	PAGE_SELLER_SETTING,
	SALE_PAGE_SETTING,
	INSTAGRAM_FEEDS_SETTING,
	PAGE_PIX_SETTING,
	PAGE_REPLACEMENT_SETTING,
	TABLE_PERSON_SETTING,
	parseSizeVariant,
}